const activityTypeEnum = {
  ASSESSMENT: 'A',
  DIVERSIFIED: 'B',
  RECOVERY: 'C',
  RECLASSIFICATION: 'D',
  CONCEPT: 'E'
}

const NOVOTECH = 932
const MATOGROSSO = 1369

const headCells = [
  {
    id: 'num',
    numeric: false,
    disablePadding: true,
    label: '#'
  },
  {
    id: 'aluno',
    numeric: false,
    disablePadding: true,
    label: 'Aluno'
  },
  {
    id: 'register',
    numeric: false,
    disablePadding: true,
    label: 'ID'
  }
]

export { activityTypeEnum, headCells, NOVOTECH, MATOGROSSO }

import theme from 'theme/designTokens'
import generateDocDefinitionsBase from '../shared/BasePage'
import { DOCUMENT_TITLE, MAX_SUBJECTS_PER_PAGE } from './constants'
import { utilsPDF } from '../shared/utils'
import buildClassInfo from './ClassInfo'
import buildTable from './Table'
import buildSignature from './Signature'

const { chunkArray } = utilsPDF

const buildPartialReportContent = ({ classInfo, students, subjects }) => {
  const subjectsChunk = chunkArray(subjects, MAX_SUBJECTS_PER_PAGE)
  const studentWithGradesChunked = students.map(
    ({ student, gradeDetails }) => ({
      student,
      gradeDetails: chunkArray(gradeDetails, MAX_SUBJECTS_PER_PAGE)
    })
  )
  return subjectsChunk.map((chunk, indexChunk) => [
    buildClassInfo(classInfo),
    ...buildTable({
      subjects: chunk,
      students: studentWithGradesChunked,
      classInfo,
      indexChunk
    }),
    buildSignature({
      signatures: classInfo.signatures,
      directorName: classInfo.directorUnitName,
      schoolName: classInfo.unit,
      city: classInfo.unitCity
    })
  ])
}

export default async function PartialReportNovotecPDF(partialReportData) {
  const signatureImage1 = partialReportData?.classInfo?.signatures[0]?.image
  const signatureImage2 = partialReportData?.classInfo?.signatures[1]?.image

  const docDefinitionsBase = await generateDocDefinitionsBase({
    definitionsToReplace: {
      styles: {
        tableHeaderCell: {
          fillColor: theme.palette.background.tableHeaderPDF,
          bold: true,
          fontSize: 7
        },
        tableBodyCell: {
          fontSize: 7
        }
      },
      ...(signatureImage1 || signatureImage2
        ? {
            images: {
              ...(signatureImage1
                ? {
                    signature1: {
                      url: partialReportData.classInfo.signatures[0].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {}),
              ...(signatureImage2
                ? {
                    signature2: {
                      url: partialReportData.classInfo.signatures[1].image,
                      headers: {
                        'Access-Control-Allow-Origin': '*'
                      }
                    }
                  }
                : {})
            }
          }
        : {}),
      pageBreakBefore: (
        currentNode,
        followingNodesOnPage,
        nodesOnNextPage,
        previousNodesOnPage
      ) => {
        const shouldBreakBefore =
          currentNode?.id === 'signatureInfo' &&
          currentNode.pageNumbers.length > 1

        return shouldBreakBefore
      }
    }
  })

  return docDefinitionsBase({
    documentTitle: DOCUMENT_TITLE,
    mainContent: buildPartialReportContent({
      classInfo: partialReportData.classInfo,
      students: partialReportData.students,
      subjects: partialReportData.subjects
    }),
    companyInfo: partialReportData.companyInfo
  })
}

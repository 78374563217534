import React, { useEffect, useState } from 'react'
import { isEmpty } from 'services/helpers/fp'
import { getStudentGradesResume } from 'services/api/professor'
import { useCurrentUser } from 'services/hooks'
import { ResumeTable } from 'components/organisms/Table'
import { PageTitle } from 'components/atoms'
import { getHeadCells } from './config'

const Resume = ({ params }) => {
  const [resume, setResume] = useState(null)
  const { professorId, classId, disciplineId } = useCurrentUser()
  const { setIsLoading, isLikeNovoTech } = params
  const headCells = getHeadCells({
    shouldShowConcept5: isLikeNovoTech
  })

  const findStudentListFromClass = async () => {
    setIsLoading(true)
    const convertByArray = JSON.parse(`[${disciplineId}]`)
    const formattedParams = {
      professorId,
      classId,
      disciplineId: convertByArray
    }
    const { data: response } = await getStudentGradesResume(formattedParams)
    setResume(response)
    setIsLoading(false)
  }

  useEffect(() => {
    findStudentListFromClass()
  }, [])

  return (
    <>
      <ResumeTable resume={resume?.students} headCells={headCells} dropouts />
      {resume?.dropouts &&
        !isEmpty(resume?.students) &&
        !isEmpty(resume?.dropouts) && (
          <>
            <PageTitle title="Alunos desistentes/cancelados/remanejados" />
            <ResumeTable
              resume={resume?.dropouts}
              headCells={headCells}
              dropouts={false}
            />
          </>
        )}
    </>
  )
}

export default Resume

import theme from '../../../../theme/designTokens'

const borderLayoutDefault = {
  hLineColor: () => theme.palette.pdf.borderLigth,
  vLineColor: () => theme.palette.pdf.borderLigth,
  hLineWidth: () => 0.1,
  vLineWidth: () => 0.1
}

const paddingLayoutDefault = {
  paddingBottom: () => 5,
  paddingLeft: () => 5,
  paddingRight: () => 5,
  paddingTop: () => 5
}

const noBorderInTop = [true, false, true, true]

const getPageHeaderBasic = (currentElement, highFinalResult) => ({
  table: {
    body: [
      [
        { image: highFinalResult.logoProzDataURL, width: 100, margin: 5 },
        {
          stack: [
            { text: 'Proz Educação', bold: true, fontSize: 16 },
            { text: 'Ata de Resultados Finais', bold: true, fontSize: 14 }
          ],
          alignment: 'center'
        }
      ]
    ],
    widths: ['*', '*']
  },
  layout: { ...borderLayoutDefault, ...paddingLayoutDefault },
  ...(currentElement > 0 && { pageBreak: 'before' })
})

const getCompanyInfo = (highFinalResults) => ({
  table: {
    body: [
      [
        {
          stack: [
            {
              text: highFinalResults.companyInfo
            },
            {
              text: highFinalResults.companyAddress
            },
            {
              text: highFinalResults.authorization
            }
          ],
          border: noBorderInTop,
          alignment: 'center'
        }
      ]
    ],
    widths: '*'
  },
  layout: { ...borderLayoutDefault, ...paddingLayoutDefault }
})

const getInfoClass = (highFinalResult) => ({
  table: {
    body: [
      [
        {
          stack: [
            { text: 'Curso', bold: true },
            { text: highFinalResult.course }
          ],
          border: noBorderInTop
        },
        {
          stack: [
            { text: 'Ano letivo', bold: true },
            { text: highFinalResult.letiveYear }
          ],
          border: noBorderInTop
        }
      ],
      [
        {
          text: [
            highFinalResult.investigationText.split('XXXXXX')[0],
            { text: highFinalResult.degree, bold: true },
            highFinalResult.investigationText.split('XXXXXX')[1]
          ],
          colSpan: 2,
          border: [true, true, true, false]
        },
        {}
      ]
    ],
    widths: ['*', 200]
  },
  layout: {
    ...borderLayoutDefault,
    ...paddingLayoutDefault
  }
})

const getTableHeader = (highFinalResult) => [
  [
    { text: 'Nº', rowSpan: 3, style: ['tableHeaderCell'] },
    { text: 'Nome', rowSpan: 3, style: ['tableHeaderCell'] },
    {
      text: 'Assistência de Enfermagem em Clínica Cirúrgica',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Assistência de Enfermagem em Clínica Cirúrgica - Estágio',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Assistência de Enfermagem nas Patologias Cardiovasculares, Respiratórias e Endócrinas',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Assistência de Enfermagem nas Patologias Cardiovasculares, Respiratórias e Endócrinas - Estágio',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Assistência de Enfermagem nas Patologias Gastrintestinais, Urinárias e Neurológicas',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Assistência de Enfermagem nas Patologias Gastrintestinais, Urinárias e Neurológicas - Estágio',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Atenção Domiciliar do Adulto e do Idoso',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: 'Atenção Domiciliar do Adulto e do Idoso - Estágio',
      colSpan: 2,
      style: ['tableHeaderCell']
    },
    {},
    {
      text: `Total de: ${highFinalResult.workLoad}`,
      rowSpan: 2,
      style: ['tableHeaderCell']
    }
  ],
  [
    {},
    {},
    { text: '75h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '50h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '75h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '70h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '75h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '70h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '75h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    { text: '30h', colSpan: 2, style: ['tableHeaderCell'] },
    {},
    {}
  ],
  [
    {},
    {},
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Nota', style: ['tableHeaderCell'] },
    { text: 'Falta', style: ['tableHeaderCell'] },
    { text: 'Resultado final', style: ['tableHeaderCell'] }
  ]
]

const getTableContent = (highFinalResult) =>
  highFinalResult.results.map((result, index) => [
    { text: index + 1, style: ['tableBodyCell'] },
    { text: result.name, fontSize: 7 },
    {
      text: result.d1.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d1.foul, style: ['tableBodyCell'] },
    {
      text: result.d2.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d2.foul, style: ['tableBodyCell'] },
    {
      text: result.d3.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d3.foul, style: ['tableBodyCell'] },
    {
      text: result.d4.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d4.foul, style: ['tableBodyCell'] },
    {
      text: result.d5.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d5.foul, style: ['tableBodyCell'] },
    {
      text: result.d6.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d6.foul, style: ['tableBodyCell'] },
    {
      text: result.d7.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d7.foul, style: ['tableBodyCell'] },
    {
      text: result.d8.note.toFixed(2).replace('.', ','),
      style: ['tableBodyCell']
    },
    { text: result.d8.foul, style: ['tableBodyCell'] },
    { text: result.resultFinal, style: ['tableBodyCell'] }
  ])

const getTableFooter = () => [
  [
    {
      text: 'Observações',
      colSpan: 19,
      bold: true,
      fillColor: theme.palette.background.tableFooterPDF
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ],
  [
    {
      text: 'É considerado APROVADO(A) o(a) aluno(a) que obtiver a média final, igual ou superior a 6 (seis) pontos em cada disciplina e frequência igual ou superior a 75% (setenta e cinco) do total da carga horária.',
      colSpan: 19
    },
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {}
  ]
]

const getTable = (highFinalResult) => ({
  table: {
    body: [
      ...getTableHeader(highFinalResult),
      ...getTableContent(highFinalResult),
      ...getTableFooter()
    ],
    widths: [
      'auto',
      80,
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      '*',
      55
    ]
  },
  layout: {
    ...borderLayoutDefault,
    ...paddingLayoutDefault
  }
})

const getFooterPage = (currentPage, pageCount) => ({
  table: {
    body: [
      [
        {
          text: `${currentPage.toString()}/${pageCount}`,
          alignment: 'right'
        }
      ]
    ],
    widths: '*'
  },
  layout: { defaultBorder: false },
  margin: [10, 10, 10, 0]
})

const getSignature = () => ({
  id: 'signature',
  table: {
    body: [
      [
        {},
        {
          text: 'E para constar, eu Secretário(a) Escolar Taynara da Silva Cruz , lavrei a presente Ata que vai assinada por mim e pelo(a) Diretor(a) da Instituição de Ensino Siméia Ferreira Soares.',
          colSpan: 3,
          alignment: 'center'
        },
        {},
        {},
        {}
      ],
      [
        {
          text: `São Paulo, ${new Date().toLocaleString('pt-BR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}`,
          colSpan: 5,
          alignment: 'center'
        },
        {},
        {},
        {},
        {}
      ],
      [
        {},
        {
          stack: [
            {
              text: 'Siméia Ferreira Soares',
              bold: true,
              margin: [0, 5, 0, 5]
            },
            { text: 'Diretor', margin: [0, 0, 0, 5] },
            { text: 'RG 40.434.232-2' }
          ],
          alignment: 'center',
          border: [false, true, false, false]
        },
        {},
        {
          stack: [
            {
              text: 'Taynara da Silva Cruz',
              bold: true,
              margin: [0, 5, 0, 5]
            },
            { text: 'Secretaria Escolar', margin: [0, 0, 0, 5] },
            { text: 'RG 52.435.264-1' }
          ],
          alignment: 'center',
          border: [false, true, false, false]
        },
        {}
      ]
    ],
    widths: ['*', '*', '*', '*', '*'],
    heights: [50, 50, 'auto']
  },
  layout: {
    defaultBorder: false,
    hLineWidth: () => 0.1,
    vLineWidth: () => 0.1
  },
  marginTop: 20
})

const getContent = (highFinalResults) =>
  highFinalResults.map((highFinalResult, index) => [
    getPageHeaderBasic(index, highFinalResult),
    getCompanyInfo(highFinalResult),
    getInfoClass(highFinalResult),
    getTable(highFinalResult),
    getSignature()
  ])

const getHighFinalResultsTemplatePDF = (highFinalResults) =>
  new Promise((resolve, reject) => {
    try {
      const docDefinitions = {
        content: [...getContent(highFinalResults)],
        footer: getFooterPage,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        info: {
          title: 'Boletim escolar'
        },
        defaultStyle: {
          fontSize: 8,
          font: 'RobotoLight'
        },
        styles: {
          marginDefault: { margin: 5 },
          tableHeaderCell: {
            fillColor: theme.palette.background.tableHeaderPDF,
            bold: true,
            alignment: 'center',
            fontSize: 7
          },
          tableBodyCell: {
            alignment: 'center',
            fontSize: 7
          }
        },
        pageMargins: [20, 20, 20, 30],
        pageBreakBefore(
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        ) {
          if (
            currentNode.pageNumbers.length > 1 &&
            currentNode?.id === 'signature'
          ) {
            return true
          }

          return false
        }
      }
      resolve(docDefinitions)
    } catch (error) {
      console.error('Erro em gerar PDF', error)
      reject(error)
    }
  })

export { getHighFinalResultsTemplatePDF }

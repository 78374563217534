/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TemplateDefaultHeaderByContent } from 'components/templates/Admin'
import { ROUTE } from 'services/constants'
import { useMakeService, usePaginationBasic, useSnack } from 'services/hooks'
import { ModalConfirm } from 'components/molecules'
import { FormSchoolClassDatails } from './components/Form'
import {
  breadCrumbsItems,
  colors,
  defaultItemsPerPage,
  icons,
  initialBigNumbers,
  initialFormValues,
  initialStaticFormValues,
  lisBigNumbers
} from './constants'
import * as Styled from './style'
import {
  getDetails,
  getListSCDisciplines,
  updateSchoolClasses
} from './services'
import TableVariables from './components/Table'
import { getListCoordinators } from '../CreateByUpdateClass/services'

const ClassDetails = () => {
  const snackProps = useSnack()
  const { setSnack } = snackProps
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const { id, unit } = params
  const navigate = useNavigate()
  const [openModalAddTeacher, setOpenModalAddTeacher] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [listDisciplines, setListDisciplines] = useState([])
  const [listSchedules, setListSchedules] = useState([])
  const [listSelectListDisciplines, setSelectListDisciplines] = useState([])
  const [listGradeSheets, setListGradeSheets] = useState([])
  const [listCodinator, setListCodinator] = useState([])
  const [formValues, setFormValues] = useState(initialFormValues)
  const [filter, setFilter] = useState(defaultItemsPerPage)
  const [staticFormValues, setStaticFormValues] = useState(
    initialStaticFormValues
  )
  const [bigNumbers, setBigNumbers] = useState(initialBigNumbers)
  const [loadingOpen, setLoadingOpen] = useState(false)
  const [itensPerPage, setItensPerPage] = useState(defaultItemsPerPage)
  const [count, setCount] = useState(null)

  const paramsMakeService = {
    isModule: true,
    setSnack
  }
  const { modules } = useMakeService(paramsMakeService)

  const { page, totalPage, setPage, items } = usePaginationBasic(
    listDisciplines,
    filter,
    count
  )

  useEffect(() => {
    getListCoordinators({
      setSnack,
      setLoadingVisibility: setLoadingOpen,
      setListCodinator
    })
  }, [])

  useEffect(() => {
    getDetails({
      id,
      setSnack,
      setLoadingOpen,
      setFormValues,
      setStaticFormValues,
      setListDisciplines,
      setCount,
      page,
      setSelectListDisciplines,
      listCodinator,
      filter,
      setBigNumbers,
      setListGradeSheets,
      setListSchedules
    })
  }, [listCodinator])

  const onHandleConfirm = () => {
    setOpenModalConfirm(false)
    navigate(ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS, {
      state: { savedSearch: true }
    })
  }

  useEffect(() => {
    if (page)
      getListSCDisciplines({
        id,
        setSnack,
        setListDisciplines,
        setCount,
        page,
        filter,
        setLoadingOpen
      })
  }, [page, filter])

  return (
    <TemplateDefaultHeaderByContent
      snackProps={snackProps}
      breadcrumbsItems={breadCrumbsItems(id)}
      classNameHeader="goBack"
      loadingOpen={loadingOpen}
      onClickButton={() => setOpenModalConfirm(true)}
      open={openModalConfirm}
      handleCloseModal={() => setOpenModalConfirm(false)}
      handleConfirmModal={() => onHandleConfirm()}
      content={
        <>
          <FormSchoolClassDatails
            openModalAddTeacher={openModalAddTeacher}
            listCards={lisBigNumbers}
            setFormValues={setFormValues}
            setStaticFormValues={setStaticFormValues}
            setListGradeSheets={setListGradeSheets}
            listGradeSheets={listGradeSheets}
            setSnack={setSnack}
            setLoadingOpen={setLoadingOpen}
            formValues={formValues}
            staticFormValues={staticFormValues}
            modules={modules}
            listCodinator={listCodinator}
            classId={id}
            updateSchoolClasses={updateSchoolClasses}
            setOpenModalConfirm={setOpenModalConfirm}
          />

          <TableVariables
            setOpenModalAddTeacher={setOpenModalAddTeacher}
            listCards={lisBigNumbers}
            iconsCard={icons}
            unit={unit}
            colorsCard={colors}
            listSelectListDisciplines={listSelectListDisciplines}
            setSnack={setSnack}
            listSchedules={listSchedules}
            setLoadingOpen={setLoadingOpen}
            listDisciplines={items}
            setOpenModalConfirm={setOpenModalConfirm}
            setListDisciplines={setListDisciplines}
            filter={filter}
            setCount={setCount}
            bigNumbers={bigNumbers}
            setFilter={setFilter}
            totalPage={totalPage}
            formValues={formValues}
            staticFormValues={staticFormValues}
            page={page}
            setPage={setPage}
            count={count}
            itensPerPage={itensPerPage}
            id={id}
            setFormValues={setFormValues}
            setStaticFormValues={setStaticFormValues}
            setSelectListDisciplines={setSelectListDisciplines}
            setBigNumbers={setBigNumbers}
            setListGradeSheets={setListGradeSheets}
          />

          <ModalConfirm
            open={openModalConfirm}
            icon={<Styled.IconWarningAmber />}
            title="Cancelar"
            message="Deseja cancelar a operação atual? Você perderá as alterações realizadas."
            size="medium"
            handleClose={() => setOpenModalConfirm(false)}
            textButtonNotConfirm="Não"
            textButtonConfirm="Sim"
            onClickNotConfirm={() => setOpenModalConfirm(false)}
            onClickConfirm={() => setOpenModalConfirm(false)}
            colorButtonConfirm="error"
          />
        </>
      }
    />
  )
}

export default ClassDetails

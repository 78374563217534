import buildCompanyInfo from './CompanyInfo'
import buildFooterDefault from './Footer'
import buildHeader from './Header'
import buildSignature from './Signature'

const getContent = async ({ mainContent, documentTitle, companyInfo }) =>
  Promise.all(
    mainContent.map(async (element, index) => {
      const signature = element?.signature
        ? await buildSignature(element?.signature)
        : []

      return [
        buildHeader({
          indexOfCurrentElement: index,
          documentTitle
        }),
        ...buildCompanyInfo(companyInfo),
        element,
        ...signature
      ]
    })
  )

export default async function generateDocDefinitionsBase({
  definitionsToReplace = {}
} = {}) {
  const docDefinitions = {
    defaultStyle: {
      fontSize: 8,
      font: 'RobotoLight',
      lineHeight: 1.1
    },
    pageMargins: [20, 20, 20, 30],
    pageOrientation: 'landscape',
    footer: buildFooterDefault,
    ...definitionsToReplace
  }

  return async ({
    mainContent = [],
    companyInfo,
    documentTitle = 'Título do documento'
  }) => {
    docDefinitions.content = await getContent({
      documentTitle,
      mainContent,
      companyInfo
    })
    docDefinitions.info = {
      title: documentTitle
    }
    docDefinitions.pageBreakBefore = (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) => {
      const shouldBreakBefore =
        currentNode?.id === 'signature' && currentNode.pageNumbers.length > 1

      return (
        shouldBreakBefore ||
        definitionsToReplace?.pageBreakBefore?.(
          currentNode,
          followingNodesOnPage,
          nodesOnNextPage,
          previousNodesOnPage
        )
      )
    }

    return docDefinitions
  }
}

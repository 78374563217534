import { convertDateToUTC, isEmpty } from 'services/helpers'
import theme from 'theme/designTokens'
import { utilsPDF } from 'components/templates/PDF/shared/utils'

const {
  addAsteristic,
  handlePartialGrade,
  handleFinalGrade,
  colors,
  handleGrade
} = utilsPDF

const STUDENT_DISCIPLINE_SITUATION = {
  STUDYING: 'studying',
  TO_ATTEND: 'to_attend',
  FINALIZED: 'default'
}

const SCHOOL_REPORT_RESULT = {
  APPROVED: 'Aprovado',
  RECLASSIFICATION: 'Reclassificação',
  RECOVERY: 'Recuperação',
  STUDYING: 'Cursando',
  DISAPPROVED: 'Reprovado',
  TO_ATTEND: 'A cursar',
  IN_CLOSING: 'Em Fechamento'
}

const formatResult = (value, color) => ({ value, color })

const getIsClosing = ({ disciplineEndAt, situation, daysToClose = 2 }) => {
  if (situation !== STUDENT_DISCIPLINE_SITUATION.FINALIZED) return false

  const today = convertDateToUTC(new Date())
  const endDateWith3DaysAdded = convertDateToUTC(disciplineEndAt)
  endDateWith3DaysAdded.setDate(endDateWith3DaysAdded.getDate() + daysToClose)

  return endDateWith3DaysAdded >= today
}

const getResult = ({ gradeDetail, professorDaysGrade }) => {
  const {
    student: { situation, approved },
    discipline: { endAt },
    grade: { approvedByGrade, reclassificationGrade, recoveryGrade },
    frequency: { approvedByFrequency }
  } = gradeDetail

  const { TO_ATTEND, STUDYING } = STUDENT_DISCIPLINE_SITUATION
  const isClosing = getIsClosing({
    disciplineEndAt: endAt,
    situation,
    daysToClose: professorDaysGrade
  })

  if (situation === TO_ATTEND) {
    return formatResult(SCHOOL_REPORT_RESULT.TO_ATTEND, colors.black)
  }

  if (situation === STUDYING) {
    return formatResult(SCHOOL_REPORT_RESULT.STUDYING, colors.grey)
  }

  if (isClosing) {
    return formatResult(SCHOOL_REPORT_RESULT.IN_CLOSING, colors.grey)
  }

  if (approved || (reclassificationGrade && approvedByGrade)) {
    return formatResult(SCHOOL_REPORT_RESULT.APPROVED, colors.green)
  }

  if (
    approvedByGrade &&
    !approvedByFrequency &&
    isEmpty(reclassificationGrade)
  ) {
    return formatResult(
      SCHOOL_REPORT_RESULT.RECLASSIFICATION,
      colors.yellowDark
    )
  }

  if (!approvedByGrade && approvedByFrequency && !recoveryGrade) {
    return formatResult(SCHOOL_REPORT_RESULT.RECOVERY, colors.yellowLight)
  }

  return formatResult(SCHOOL_REPORT_RESULT.DISAPPROVED, colors.red)
}

const handleFrequency = (frequency) => {
  if (frequency.approvedByFrequency) {
    return frequency.absence
  }

  return addAsteristic(frequency.absence)
}

export default function buildTableBody({ classInfo, student }) {
  return student.gradeDetails.map((gradeDetail) => {
    const partialGrade = handlePartialGrade({
      partialGrade: gradeDetail.grade.partialGrade,
      minimumGrade: classInfo.minimumGrade
    })
    const finalGrade = handleFinalGrade({
      finalGrade: gradeDetail.grade.finalGrade,
      hasAcademicPerformance: gradeDetail.student.has_academic_performance
    })
    const result = getResult({
      gradeDetail,
      professorDaysGrade: classInfo.professorDaysGrade
    })

    return [
      {
        text: gradeDetail.discipline.name,
        style: ['tableBodyCell']
      },
      {
        text: handleGrade({
          grade: gradeDetail.grade.assessmentGrade
        }),
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: handleGrade({
          grade: gradeDetail.grade.activitiesGrade
        }),
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: handleGrade({
          grade: gradeDetail.grade.recoveryGrade
        }),
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: handleGrade({
          grade: gradeDetail.grade.reclassificationGrade
        }),
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: partialGrade.grade,
        style: ['tableBodyCell'],
        alignment: 'center',
        color: partialGrade.color
      },
      {
        text: finalGrade,
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: handleFrequency(gradeDetail.frequency),
        style: ['tableBodyCell'],
        alignment: 'center'
      },
      {
        text: result.value,
        style: ['tableBodyCell'],
        alignment: 'center',
        color: result.color,
        bold: true
      }
    ]
  })
}

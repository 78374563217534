import { clone } from 'services/helpers'
import buildSignatureBase from '../../shared/BasePage/Signature'
import { utilsPDF } from '../../shared/utils'

const { borderLayoutDefault } = utilsPDF

const buildSignature = ({ signatures, schoolName, directorName, city }) => {
  const signature = buildSignatureBase(signatures, city)
  return {
    id: 'signatureInfo',
    margin: [0, 10, 0, 0],
    stack: [
      {
        table: {
          body: [
            [
              {
                text: `E para constar, eu Secretário(a) ${schoolName} , lavrei a presente Ata que vai assinada por mim e pelo(a) Diretor(a) da Instituição de Ensino ${directorName}.`,
                border: [false, false, false, false],
                alignment: 'center'
              }
            ]
          ],
          widths: '*',
          layout: {
            ...borderLayoutDefault,
            paddingBottom: () => 20,
            paddingLeft: () => 20,
            paddingRight: () => 20,
            paddingTop: () => 20
          }
        }
      },
      clone(signature)
    ]
  }
}

export default buildSignature

import { ACTIVITY_NOVOTEC_IDS } from '../../constants'

const getActivity = ({ activities, bimester, isOnline }) => {
  const activityNovotecBimesterMap = {
    1: [
      { id: ACTIVITY_NOVOTEC_IDS.B1_AVC_DOCENTE, isOnline: false },
      { id: ACTIVITY_NOVOTEC_IDS.B1_AVA_INTEGRADA, isOnline: true }
    ],
    2: [
      { id: ACTIVITY_NOVOTEC_IDS.B2_AVC_DOCENTE, isOnline: false },
      { id: ACTIVITY_NOVOTEC_IDS.B2_AVA_INTEGRADA, isOnline: true }
    ],
    3: [
      { id: ACTIVITY_NOVOTEC_IDS.B3_AVC_DOCENTE, isOnline: false },
      { id: ACTIVITY_NOVOTEC_IDS.B3_AVA_INTEGRADA, isOnline: true }
    ],
    4: [
      { id: ACTIVITY_NOVOTEC_IDS.B4_AVC_DOCENTE, isOnline: false },
      { id: ACTIVITY_NOVOTEC_IDS.B4_AVA_INTEGRADA, isOnline: true }
    ]
  }
  const foundActivity = activities.find((activity) => {
    const activityMapped = activityNovotecBimesterMap[bimester].find(
      (el) => el.isOnline === isOnline
    )
    return activity.id === activityMapped.id
  })
  return foundActivity.activity
}

const buildTableHeader = (activities) => {
  const prepareActivities = [
    { activitiesList: activities, bimester: 1, isOnline: false },
    { activitiesList: activities, bimester: 1, isOnline: true },
    { activitiesList: activities, bimester: 2, isOnline: false },
    { activitiesList: activities, bimester: 2, isOnline: true },
    { activitiesList: activities, bimester: 3, isOnline: false },
    { activitiesList: activities, bimester: 3, isOnline: true },
    { activitiesList: activities, bimester: 4, isOnline: false },
    { activitiesList: activities, bimester: 4, isOnline: true }
  ]

  return [
    [
      {
        text: '',
        border: [true, false, true, false],
        style: ['tableHeaderCell']
      },
      ...new Array(3).fill({
        text: '',
        border: [false, false, true, false],
        style: ['tableHeaderCell']
      }),
      {
        text: 'Módulo',
        bold: true,
        alignment: 'center',
        colSpan: 10,
        style: ['tableHeaderCell']
      },
      ...new Array(9).fill({}),
      {
        text: 'Conselho final',
        bold: true,
        alignment: 'center',
        colSpan: 2,
        style: ['tableHeaderCell']
      },
      {}
    ],
    [
      {
        text: 'Nº',
        bold: true,
        style: ['tableHeaderCell'],
        border: [true, false, true, false],
        alignment: 'center'
      },
      {
        text: 'Nome',
        bold: true,
        style: ['tableHeaderCell'],
        border: [false, false, true, false]
      },
      {
        text: 'Faltas',
        bold: true,
        style: ['tableHeaderCell'],
        border: [false, false, true, false]
      },
      {
        text: '% Faltas',
        bold: true,
        style: ['tableHeaderCell'],
        border: [false, false, true, false],
        alignment: 'center'
      },
      {
        text: '1º Bimestre',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        colSpan: 2
      },
      {},
      {
        text: '2º Bimestre',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        colSpan: 2
      },
      {},
      {
        text: '3º Bimestre',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        colSpan: 2
      },
      {},
      {
        text: '4º Bimestre',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        colSpan: 2
      },
      {},
      {
        text: 'Rec. final',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        rowSpan: 2
      },
      {
        text: 'Reclassificação',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        rowSpan: 2
      },
      {
        text: '5º Conceito',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        rowSpan: 2
      },
      {
        text: 'Nota final',
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center',
        rowSpan: 2
      }
    ],
    [
      {
        style: ['tableHeaderCell'],
        text: '',
        border: [true, false, true, true]
      },
      ...new Array(3).fill({
        style: ['tableHeaderCell'],
        text: '',
        border: [false, false, true, true]
      }),
      ...prepareActivities.map(({ activitiesList, bimester, isOnline }) => ({
        text: getActivity({ activities: activitiesList, bimester, isOnline }),
        bold: true,
        style: ['tableHeaderCell'],
        alignment: 'center'
      })),
      ...new Array(4).fill({})
    ]
  ]
}
export default buildTableHeader

import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import PDF from 'services/helpers/PDF'
import { Button } from 'components/atoms'
import { getHighFinalResultsTemplatePDF } from 'components/templates/PDF/HighFinalResults'
import { TemplateDefaultFormAndResult } from 'components/templates/Admin'
import FrequencyDiaryPDF from 'components/templates/PDF/FrequencyDiary'
import { frequencyDiaryDataMock } from 'components/templates/PDF/FrequencyDiary/mock'
import noteDiaryNovotecPDF from 'components/templates/PDF/NoteDiary/Novotec'
import { noteDiaryDataMock } from 'components/templates/PDF/NoteDiary/Novotec/mock'
import PartialReportNovotecPDF from 'components/templates/PDF/PartialReport'
import { partialReportDataMock } from 'components/templates/PDF/PartialReport/mock'
import SchoolReportPDF from 'components/templates/PDF/SchoolReport/General'
import { schoolReportDataMock } from 'components/templates/PDF/SchoolReport/General/mock'
import { getSchoolReportToPDF } from 'services/api/admin'
import { noteDiaryGeneralPDF } from 'components/templates/PDF/NoteDiary/General'
import { noteDiaryGeneralMock } from 'components/templates/PDF/NoteDiary/General/mock'
import { highFinalResults } from './dataMock'

export default function POCPdf() {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])
  const [loading, setLoading] = useState(false)
  const pdf = PDF()

  const getArrayWithTotalOfPagesToRender = (quantity = 1, objectToFill) =>
    !Number.isNaN(+quantity)
      ? new Array(Math.ceil(+quantity / 2)).fill(objectToFill)
      : []

  const openHighFinalResultsPDF = async () => {
    const list = getArrayWithTotalOfPagesToRender(
      params.pages,
      highFinalResults[0]
    )
    const docDefinitions = await getHighFinalResultsTemplatePDF(list)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openFrequencyDiaryPDF = async () => {
    const docDefinitions = await FrequencyDiaryPDF(frequencyDiaryDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openNoteDiaryNovotecPDF = async () => {
    const docDefinitions = await noteDiaryNovotecPDF(noteDiaryDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }
  const openNoteDiaryGeneralPDF = async () => {
    const docDefinitions = await noteDiaryGeneralPDF(noteDiaryGeneralMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openPartialReportNovotecPDF = async () => {
    const docDefinitions = await PartialReportNovotecPDF(partialReportDataMock)
    setLoading(true)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  const openSchoolReportPDF = async () => {
    setLoading(true)
    const { data } = await getSchoolReportToPDF({ classId: 66085 })
    const docDefinitions = await SchoolReportPDF(data)
    // const docDefinitions = await SchoolReportPDF(schoolReportDataMock)
    await pdf.open({
      docDefinitions,
      onFinish: () => setLoading(false),
      onError: () => setLoading(false)
    })
  }

  useEffect(() => {
    openNoteDiaryGeneralPDF()
  }, [])

  return (
    <TemplateDefaultFormAndResult titleHeader="PDF POC" loadingOpen={loading}>
      <h1>POC pdf</h1>
      <Button onclick={openSchoolReportPDF}>Boletim escolar mockado</Button>
      <br />
      <Button onclick={openPartialReportNovotecPDF}>
        Ata parcial novotec mockado
      </Button>
      <br />
      <Button onclick={openNoteDiaryNovotecPDF}>
        Diário de notas novotec mockado
      </Button>
      <br />
      <Button onclick={openNoteDiaryGeneralPDF}>
        Diário de notas GERAL mockado
      </Button>
      <br />
      <Button onclick={openFrequencyDiaryPDF}>
        Diário de frequência mockado
      </Button>
      <br />
      <Button onclick={openHighFinalResultsPDF}>resultado final mockado</Button>
    </TemplateDefaultFormAndResult>
  )
}

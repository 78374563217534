/* eslint-disable react/no-array-index-key */
import { Link, useSearchParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { isEmpty, makeURLAccessLegacy } from 'services/helpers'
import { AddBox } from '@mui/icons-material'
import { buttons } from './constants'
import * as Styled from './style'

const Toolbar = ({
  setTypeModal,
  staticFormValues,
  setOpenModalExportFiles,
  setOpenModalAtaNovoTec,
  setOpenModalClassSchedule,
  setOpenModalExportFilesInline,
  formValues,
  numSelected,
  setFormAddProfessors,
  listDisciplines,
  setOpenModalDocuments,
  setOpenModalAddTeacher,
  setIsBatchAction,
  setOpenModalIA
}) => {
  const [searchParams] = useSearchParams()
  const params = Object.fromEntries([...searchParams])

  const handleClick = (id) => {
    setTypeModal(id)

    const actions = {
      grades: () => {
        setOpenModalExportFiles(true)
      },
      resultsTranscript: () => {
        setOpenModalExportFiles(true)
      },
      documents: () => {
        setOpenModalDocuments(true)
        setOpenModalExportFilesInline(false)
      },
      resultsPartial: () => {
        setOpenModalAtaNovoTec(true)
      },
      integratedAssessment: () => setOpenModalIA(true),
      schedules: () => setOpenModalClassSchedule(true),
      detailsReport: () =>
        makeURLAccessLegacy(
          `acesso/ata_detalhes.php?id_turma=${params.id}`,
          true
        )
    }

    const action = actions[id]
    if (action) {
      action()
    }
  }

  const buttonAction = ({ index, button }) => (
    <Styled.Button
      type="button"
      key={index}
      disabled={button.disabled}
      color="warning"
      variant="outlined"
      startIcon={button.icon}
      value={button.label}
      onClick={() => handleClick(button.id)}
    />
  )

  return (
    <>
      <Styled.Toolbar>
        {buttons(params.id, formValues, staticFormValues)?.map(
          (items, index) => (
            <Styled.Grid container spacing={{ xs: 0, sm: 2 }} key={index}>
              {items?.columns?.map((button, iRandom) => (
                <Grid item xs={12} sm={12} md={3} key={iRandom}>
                  {button.path ? (
                    <Link
                      to={button.path}
                      key={index}
                      className={button.className}
                    >
                      {buttonAction({ index, button })}
                    </Link>
                  ) : (
                    <Box className={button.className}>
                      {buttonAction({ index, button })}
                    </Box>
                  )}
                </Grid>
              ))}
            </Styled.Grid>
          )
        )}
      </Styled.Toolbar>

      {!isEmpty(listDisciplines) && (
        <Styled.ToolbarBatchActions>
          <Styled.Grid container spacing={{ xs: 0, sm: 2 }}>
            <Styled.GridBatchActions item xs={12} sm={12} md={12}>
              <Typography variant="body1" component="body1">
                Ações em lote:
              </Typography>

              <Styled.ButtonBatchActions
                type="button"
                disabled={!numSelected}
                startIcon={<AddBox />}
                variant="outlined"
                value="Adicionar Professor"
                onClick={() => {
                  setFormAddProfessors({
                    teacher: [{ professor: '', order: 1 }]
                  })
                  setIsBatchAction(true)
                  setOpenModalAddTeacher(true)
                }}
              />
            </Styled.GridBatchActions>
          </Styled.Grid>
        </Styled.ToolbarBatchActions>
      )}
    </>
  )
}

export default Toolbar

const handleGrade = ({ grade, disciplineHasAlreadyStarted }) => {
  if (grade) return grade.toFixed(2).replace('.', ',')

  return disciplineHasAlreadyStarted ? '0,0' : '-'
}

const buildTableBody = (students, indexChunk) =>
  students.map((studentEl, index) => {
    const { student, gradeDetails } = studentEl
    return [
      { text: index + 1, style: ['tableBodyCell'] },
      { text: student.name, style: ['tableBodyCell'] },
      ...gradeDetails[indexChunk]
        .map((subject) => [
          {
            text: handleGrade({
              grade: subject.grade.finalGrade,
              disciplineHasAlreadyStarted: subject.disciplineHasAlreadyStarted
            }),
            style: ['tableBodyCell'],
            alignment: 'center'
          },
          {
            text: `${Math.floor(subject.frequency.absenceInPercentage)}%`,
            style: ['tableBodyCell'],
            alignment: 'center'
          }
        ])
        .flat()
    ]
  })
export default buildTableBody

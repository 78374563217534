import {
  AccessTime,
  Autorenew,
  Edit,
  Feed,
  LibraryBooks,
  ListAlt,
  NoteAdd
} from '@mui/icons-material'
import { ROUTE, UNITS } from 'services/constants'
import { convertToParams } from 'services/helpers'

const buttons = (idClass, formValues, staticFormValues) => [
  {
    columns: [
      {
        id: 'editSubjects',
        path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_CLASS_EDIT_DISCIPLINE}?id=${idClass}`,
        icon: <Edit />,
        className: 'academico_btn_edita_disciplinas',
        label: 'Editar Disciplinas'
      },
      {
        id: 'schedules',
        icon: <AccessTime />,
        label: 'Horários',
        className: 'academico_btn_acessa_horarios',
        onClick: (setOpenModalClassSchedule) => setOpenModalClassSchedule()
      },
      {
        id: 'grades',
        icon: <ListAlt />,
        label: 'Notas',
        className: 'academico_btn_acessa_notas',
        onClick: (setOpenModalGradeMap) => setOpenModalGradeMap()
      },
      {
        id: 'documents',
        icon: <LibraryBooks />,
        label: 'Documentos',
        className: 'academico_btn_acessa_documentosTurma',
        onClick: (setOpenModalDocuments) => setOpenModalDocuments()
      }
    ]
  },

  {
    columns: [
      {
        id: 'resultsTranscript',
        icon: <Feed />,
        label: 'Ata de Resultados',
        className: 'academico_btn_acessa_ataResultados',
        onClick: (setOpenModalFinalResults) => setOpenModalFinalResults()
      },

      ...([UNITS.NOVOTEC.name, UNITS.MS.name].includes(staticFormValues.unit)
        ? [
            {
              id: 'resultsPartial',
              icon: <Feed />,
              label: 'Ata Parcial',
              className: 'academico_btn_acessa_ataParcial',
              onClick: (setOpenModalAtaNovoTec) => setOpenModalAtaNovoTec()
            }
          ]
        : []),
      {
        id: 'resultsPartial',
        icon: <Feed />,
        label: 'Ata de Parcial',
        className: 'academico_btn_acessa_ataParcial',
        onClick: (setOpenModalAtaNovoTec) => setOpenModalAtaNovoTec()
      },
      {
        id: 'detailsReport',
        icon: <Feed />,
        label: 'Ata de Detalhes',
        className: 'academico_btn_baixa_ataDetalhes'
      },
      {
        id: 'integratedAssessment',
        icon: <NoteAdd />,
        label: 'Avaliação integrada',
        className: 'academico_btn_baixa_ataDetalhes',
        onClick: (setOpenModalIA) => setOpenModalIA()
      },
      {
        id: 'reenrollment',
        path: `${ROUTE.ADMIN_SECRETARY_SCHOOL_REENROLL}?${convertToParams({
          id: idClass,
          className: formValues.code,
          course: staticFormValues.course,
          modules: staticFormValues.module
        })}`,
        icon: <Autorenew />,
        label: 'Rematrícula',
        className: 'academico_btn_acessa_rematricula'
      }
    ]
  }
]

export { buttons }

import { utilsPDF } from '../../utils'

const { borderLayoutDefault, paddingLayoutDefault, logoProzSVG } = utilsPDF

const BuildHeader = ({ indexOfCurrentElement, documentTitle }) => ({
  table: {
    body: [
      [
        { svg: logoProzSVG, width: 100, margin: 5 },
        {
          stack: [
            {
              text: 'Proz Educação',
              bold: true,
              fontSize: 16,
              lineHeight: 1.2,
              margin: [1, 0, 0, 0]
            },
            { text: documentTitle, bold: true, fontSize: 14 }
          ],
          alignment: 'center'
        }
      ]
    ],
    widths: [120, '*']
  },
  layout: { ...borderLayoutDefault, ...paddingLayoutDefault },
  ...(indexOfCurrentElement &&
    indexOfCurrentElement > 0 && { pageBreak: 'before' })
})

export default BuildHeader

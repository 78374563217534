const ACTIVITY_STATUS = {
  SENT: 'Enviado',
  DEFERRED: 'Deferido',
  REJECTED: 'Indeferido'
}

const ACTIVITY_GROUP = {
  ACTIVITIES: 'A',
  DIVERSES_ACTIVITIES: 'B',
  RECOVERY: 'C',
  RECLASSIFICATION: 'D',
  CONCEPT_5: 'E'
}

export { ACTIVITY_STATUS, ACTIVITY_GROUP }
